import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Modal,
  VStack,
  Text,
  Heading,
  useToast,
  Input,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';

import useDispatch from '@hooks/useDispatch';
import {
  commitSelector,
  userSelector,
  deviceSelector,
  selectedProjectIdSelector,
  projectFilesSelector,
  hasChangesSinceLastCommitSelector,
  anyFileHasError,
  commitIdToRunAgainSelector,
} from '@selectors';
import {
  createCommit,
  getCommits,
  setShowCommitDiff,
  setShowCommitModal,
} from '@slices/commitSlices';
import MqttService from '@/services/MqttService';
import { pushCommitToDevice } from '@/slices/deviceSlices';
import { useTranslation } from 'next-i18next';

interface SelectCommitModalProps {
  onClose?: () => void;
}

const CommitModal = (props: SelectCommitModalProps) => {
  const { onClose: onCloseCallback } = props;
  const dispatch = useDispatch();
  const toast = useToast();
  const { commits, isLoading } = useSelector(commitSelector);
  const hasChanges = useSelector(hasChangesSinceLastCommitSelector);
  const hasError = useSelector(anyFileHasError);
  const { id: userId } = useSelector(userSelector);
  const projectId = useSelector(selectedProjectIdSelector);
  const projectFiles = useSelector(projectFilesSelector);
  const { connectedDevice, isRunningCode, lastRanProjectId } = useSelector(
    deviceSelector,
  );
  const commitIdToRunAgain = useSelector(commitIdToRunAgainSelector);
  const [message, setMessage] = useState<string>('');
  const { t } = useTranslation();
  const onClose = () => {
    if (onCloseCallback) {
      onCloseCallback();
    }
    dispatch(setShowCommitModal(false));
  };

  const openDiff = () => {
    dispatch(getCommits({ projectId, userId }));
    dispatch(setShowCommitDiff(true));
    onClose();
  };

  const commitToDevice = () => {
    const files = projectFiles
      .filter((f) => !f.id.includes('lib-'))
      .map((f) => ({ id: f.id, content: f.newContent }));

    try {
      dispatch(
        createCommit({
          data: { message, files },
          projectId,
          deviceId: !!connectedDevice?.id ? connectedDevice.id : undefined,
          userId,
        }),
      );
      toast({
        title: 'Success.',
        description: 'Code sucessfully commited to device.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Something went wrong.',
        description: e,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw e;
    }
    onClose();
  };

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <form>
          <ModalHeader
            backgroundColor={'#131313'}
            outlineColor={'white'}
            textColor={'white'}
          >
            <Heading size="lg">{t('toolbar.commitToDevice')}</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            position="relative"
            backgroundColor={'#131313'}
            outlineColor={'white'}
            textColor={'white'}
          >
            <VStack alignItems="start">
              <Text fontSize="">{t("commit.commit")} #{commits.length + 1}</Text>
              <Text fontSize="">{t("commit.message")}</Text>
              <Input
                data-cy="commitModalInput"
                autoFocus
                w="full"
                onChange={(v) => setMessage(v.target.value || '')}
              />
              {isRunningCode && (
                <Alert status={hasChanges ? 'warning' : 'info'}>
                  <AlertIcon />
                  <AlertDescription>
                    {projectId === lastRanProjectId
                      ? t("run.notFinished")
                      : t("run.otherCode")}
                  </AlertDescription>
                </Alert>
              )}
            </VStack>
          </ModalBody>

          <ModalFooter
            onClick={openDiff}
            backgroundColor={'#131313'}
            outlineColor={'white'}
            textColor={'white'}
          >
            <Button
              isLoading={isLoading}
              variant="outline"
              color={'#3055f6'}
              backgroundColor={'white'}
            >
              See diff
            </Button>
            <Button
              data-cy="commitModalButton"
              marginLeft={2}
              isLoading={isLoading}
              disabled={hasError}
              colorScheme="blue"
              onClick={(e) => {
                e.preventDefault();
                if (!hasChanges) {
                  if (connectedDevice) {
                    if (lastRanProjectId === projectId) {
                      MqttService.publish(
                        `device/${connectedDevice.id}/command`,
                        JSON.stringify({ action: 'run_again' }),
                      );
                    } else {
                      dispatch(pushCommitToDevice(commitIdToRunAgain));
                    }
                  }
                  onClose();
                } else {
                  commitToDevice();
                }
              }}
              type="submit"
            >
              {hasError
                ? t("commit.fixError")
                : hasChanges
                ? isRunningCode
                  ? t("commit.commmitAnyway")
                  : connectedDevice
                  ? t('toolbar.commitToDevice')
                  : t('toolbar.commitWithoutDevice')
                : t('commit.rerunCodeNoChanges')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CommitModal;
