import React, { useState } from 'react';
import useDispatch from '@hooks/useDispatch';
import {
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';

import { sendChatMessage } from '@slices/terminalSlices';
import { useTranslation } from 'next-i18next';

const ChatInput = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation('common');
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (inputValue) {
      dispatch(sendChatMessage(inputValue));
    }
    setInputValue('');
  };

  return (
    <form onSubmit={onSubmit}>
      <InputGroup>
        <Input
          value={inputValue}
          onChange={onInputChange}
          type="text"
          placeholder="Type message"
          border="none"
        />
        <InputRightElement>
          <IconButton
            onClick={onSubmit}
            type="submit"
            aria-label={t('ariaLabel.sendMessage')}
            size="md"
            icon={<EmailIcon color="primary" />}
          >
            Send
          </IconButton>
        </InputRightElement>
      </InputGroup>
    </form>
  );
};

export default ChatInput;
