import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { NotAllowedIcon } from '@chakra-ui/icons';
import { useTransform } from 'framer-motion';
import { useTranslation } from 'next-i18next';

interface LogoutProps {
  onClick: () => void;
  isDisabled: boolean;
}

const Logout = (props: LogoutProps) => {
  const { isDisabled, onClick } = props;
  const { t } = useTranslation('common');
  return (
    <IconButton
      bg="itemBackground"
      color="#1e1e1e"
      aria-label={t('ariaLabel.logout')}
      icon={<NotAllowedIcon />}
      isDisabled={isDisabled}
      onClick={onClick}
    />
  );
};

export default Logout;
