import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useConfirm } from 'chakra-confirm';
import { RiGitRepositoryCommitsFill } from 'react-icons/ri';
import classNames from 'classnames';

import config from '@config';
import {
  rightSidebarSelector,
  editorSelector,
  reverseCommitsSelector,
  commitSelector,
  deviceSelector,
  isProjectEmbededPython,
  anyFileHasChangesSelector,
} from '@selectors';
import {
  loadCommit,
  setCommitDiffIndex,
  setDiffWithWorkingDraft,
  setShowCommitDiff,
  setShowCommitModal,
} from '@slices/commitSlices';
import { pushCommitToDevice } from '@slices/deviceSlices';
import useDispatch from '@hooks/useDispatch';

import DiffCommit from './Diff/DiffCommit';
import { useTranslation } from 'next-i18next';

const RightSidebar = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const confirmExit = useConfirm({
    title: 'Are you sure?',
    body: 'You have unsaved changes, this action will override them.',
  });
  const commitList = useRef<HTMLDivElement>();
  const isRightSidebarOpen = useSelector(rightSidebarSelector);
  const isEmbedPython = useSelector(isProjectEmbededPython);
  const editor = useSelector(editorSelector);
  const commits = useSelector(reverseCommitsSelector);
  const hasChanges = useSelector(anyFileHasChangesSelector);
  const { t } = useTranslation('common');
  const { connectedDevice } = useSelector(deviceSelector);
  const { diffWithWorkingDraft, showDiff: showCommitDiff } = useSelector(
    commitSelector,
  );
  const currentCommitIndex = commits.findIndex(
    (c) => c.id === connectedDevice?.currentCommitId,
  );

  const hasOverflow = useMemo(() => {
    if (!commitList.current) return false;
    return (
      commitList.current.offsetHeight < commitList.current.scrollHeight ||
      commitList.current.offsetWidth < commitList.current.scrollWidth
    );
  }, [commits, commitList]);

  const onSelect = (index: number) => {
    dispatch(setShowCommitDiff(true));
    dispatch(setCommitDiffIndex(index));
  };
  const onLoadCommit = async (index: number) => {
    if (hasChanges) {
      const confirm = await confirmExit();
      if (!confirm) {
        return;
      }
    }
    try {
      dispatch(loadCommit(index));
      toast({
        title: t('toast.newUpdate.status.success'),
        description: 'Loaded commit.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (e) {
      toast({
        title: 'Something went wrong.',
        description: e,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      throw e;
    }
  };
  const onPush = (index: number) => {
    const c = commits[commits.length - index - 1];
    dispatch(pushCommitToDevice(c.id));
  };
  return (
    <Box
      position="absolute"
      background="background"
      bgColor="#181818"
      display={editor.isSmall ? 'none' : 'block'}
      h="100vh"
      right={0}
      overflow="hidden"
    >
      <VStack
        w={config.rightSidebarWidth}
        mr={isRightSidebarOpen ? 0 : `-${config.rightSidebarWidth}px`}
        background="background"
        h="calc(100% - 90px)"
        mt="44px"
        bgColor="#181818"
        justifyContent="space-between"
        transition="0.4s"
        alignItems="flex-start"
        overflow="auto"
        py={2}
      >
        <VStack w="full" px={0} spacing={0} overflow="auto">
          <Checkbox
            pb={4}
            size="md"
            iconSize="16px"
            borderColor="primaryLighterNoA"
            checked={diffWithWorkingDraft}
            color="white"
            onChange={(e) =>
              dispatch(setDiffWithWorkingDraft(e.target.checked))
            }
            lineHeight={1}
          >
            Compare with <br />
            working draft
          </Checkbox>
          <Button
            h="max-content"
            variant="outline"
            className={classNames({
              selected: !showCommitDiff,
            })}
            pr={hasOverflow ? 8 : 4}
            sx={{
              '&': {
                width: '100%',
                borderRadius: '4px',
                background: 'var(--chakra-colors-itemBackground)',
                backgroundColor: '#37373c',
              },
              '&:hover': {
                background: '#243473',
              },
              '&.selected': {
                background: 'primary',
                color: 'white',
              },
              '&.selected .btn': {
                background: 'primaryLighterNoA',
                color: 'primaryLighter',
              },
              '& .btn:hover': {
                background: 'primaryLighterNoA',
                color: 'primaryLighter',
              },
              '&:not(.selected)': {
                color: 'unset',
              },
              '&.selected:hover': {
                background: 'transparent',
                color: 'unset',
              },
              '& .commit-nm': {
                color: 'white',
              },
              '&.selected .commit-nm': {
                color: 'white',
              },
              '&.selected:hover .commit-nm': {
                color: '#243473',
              },
              '& .commit-dt': {
                color: 'gray-500',
                fontSize: 12,
              },
              '& .commit-msg': {
                flexGrow: 1,
              },
              '& .commit-actions': {
                color: 'unset',
              },
              '&:focus': {
                zIndex: 1,
              },
            }}
            onClick={() => dispatch(setShowCommitDiff(false))}
          >
            <HStack w="full" justifyContent="space-between" textColor="white">
              <VStack py={2}>
                <HStack
                  w="full"
                  // onClick={() => dispatch(setShowCommitDiff(false))}
                >
                  <Text
                    className="commit-nm"
                    // onClick={() => dispatch(setShowCommitDiff(false))}
                  >
                    #{commits.length + 1}
                  </Text>
                  <Text
                    className="commit-msg"
                    // onClick={() => dispatch(setShowCommitDiff(false))}
                  >
                    Working draft
                  </Text>
                </HStack>
                <HStack
                  w="full"
                  // onClick={() => dispatch(setShowCommitDiff(false))}
                >
                  <Text
                    className="commit-dt"
                    // onClick={() => dispatch(setShowCommitDiff(false))}
                  >
                    now
                  </Text>
                </HStack>
              </VStack>

              <Tooltip
                shouldWrapChildren
                label={
                  hasChanges
                    ? t('toolbar.commitToDevice')
                    : 'No changes since last commit'
                }
                color="white"
              >
                {!!connectedDevice?.id && (
                  <IconButton
                    className="btn"
                    aria-label={t('ariaLabel.pushCommitToDevice')}
                    bg="primary"
                    icon={
                      <RiGitRepositoryCommitsFill
                        className="icon"
                        color="white"
                      />
                    }
                    disabled={!hasChanges}
                    onClick={() => dispatch(setShowCommitModal(true))}
                  />
                )}
              </Tooltip>
            </HStack>
          </Button>
          <VStack
            w="full"
            px={0}
            spacing={0}
            left={isRightSidebarOpen ? '0px' : -config.rightSidebarWidth}
            overflow="auto"
            ref={commitList}
          >
            {commits.map((commit, index) => (
              <DiffCommit
                key={commit.id}
                commit={commit}
                index={commits.length - index - 1}
                currentCommit={currentCommitIndex === index}
                onSelect={onSelect}
                onLoadCommit={onLoadCommit}
                onPush={onPush}
              />
            ))}
          </VStack>
        </VStack>
      </VStack>
    </Box>
  );
};

export default RightSidebar;
