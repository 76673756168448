import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  IconButton,
  Button,
  HStack,
  Heading,
} from '@chakra-ui/react';
import { Resizable } from 're-resizable';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import useDispatch from '@hooks/useDispatch';
import {
  isProjectPython,
  terminalSelector,
  pythonIsKilling,
  pythonIsRunning,
  showUsbUploadButtonSelector,
  selectedProjectIdSelector,
  espDeviceSelector,
} from '@selectors';
import {
  setShowTerminal,
  setMinimizeTerminal,
  killPython,
  flashEsp,
} from '@slices/terminalSlices';
import { MessageDirection } from '@projectTypes/Terminal';
import { setIsKilling } from '@slices/pythonSlices';
import TerminalContainer from './TerminalContainer';
import { useTranslation } from 'next-i18next';

const Terminal = () => {
  const dispatch = useDispatch();
  const [terminalName, setTerminalName] = useState('Terminal');

  const terminal = useSelector(terminalSelector);
  const isPython = useSelector(isProjectPython);
  const isRunning = useSelector(pythonIsRunning);
  const isKilling = useSelector(pythonIsKilling);
  const espDevice = useSelector(espDeviceSelector);
  const showUsbUploadButton = useSelector(showUsbUploadButtonSelector);
  const selectedProjectId = useSelector(selectedProjectIdSelector);
  const { t } = useTranslation('common');
  const { messages } = terminal;

  useEffect(() => {
    for (let i = messages.length - 1; i >= 0; i -= 1) {
      if (messages[i].direction === MessageDirection.Outbound) {
        setTerminalName(messages[i].content || 'Terminal');
      }
    }
  }, [messages]);

  const onKill = () => {
    dispatch(setIsKilling(true));

    ((dispatch(killPython()) as unknown) as Promise<void>).finally(() => {
      dispatch(setIsKilling(false));
    });
  };

  const onClose = () => {
    dispatch(setShowTerminal(false));
  };

  const minimizeTerminal = () => {
    dispatch(setMinimizeTerminal(!terminal.minimizeTerminal));
  };

  const flashOverUsb = () => {
    dispatch(flashEsp({ projectId: selectedProjectId, hardReset: false }));
  };

  return (
    <Drawer
      allowPinchZoom
      isOpen={terminal.showTerminal}
      placement="bottom"
      onClose={onClose}
      size="md"
      trapFocus={false}
      blockScrollOnMount={false}
    >
      <DrawerOverlay pointerEvents="none" bg="transparent">
        <DrawerContent
          pointerEvents="auto"
          bg="#151515"
          style={{ scrollbarWidth: 'none' }}
        >
          <DrawerCloseButton
            color="white"
            zIndex={10}
            _hover={{ bg: 'gray.600' }}
            _active={{ bg: 'gray.700' }}
          />
          <Resizable
            key={terminal.minimizeTerminal ? 1 : 0}
            defaultSize={{ height: 250, width: '100%' }}
            handleStyles={{ top: { height: '50px' } }}
            style={{ paddingBottom: '20px' }}
            {...(terminal.minimizeTerminal
              ? { size: { width: '100%', height: 40 } }
              : {})}
          >
            <DrawerHeader
              color="white"
              padding="16px"
              flexDirection="row"
              justifyContent="space-between"
              width="100%"
            >
              <Heading size="sm" lineHeight="1em">
                {terminalName}
              </Heading>

              <HStack pos="absolute" right="12" top="2" zIndex={10}>
                {isPython && (
                  <Button
                    colorScheme="red"
                    size="sm"
                    cursor="pointer"
                    isLoading={isKilling}
                    isDisabled={!isRunning}
                    onClick={onKill}
                  >
                    Stop running program
                  </Button>
                )}
                {showUsbUploadButton && (
                  <Button
                    colorScheme="blue"
                    size="sm"
                    cursor="pointer"
                    onClick={flashOverUsb}
                  >
                    New usb upload
                  </Button>
                )}
                <IconButton
                  icon={
                    terminal.minimizeTerminal ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )
                  }
                  onClick={minimizeTerminal}
                  size="sm"
                  variant="ghost"
                  _hover={{ bg: 'gray.600' }}
                  _active={{ bg: 'gray.700' }}
                  color="white"
                  aria-label={t('ariaLabel.minimize')}
                  boxShadow="none"
                />
              </HStack>
            </DrawerHeader>

            <DrawerBody h="100%" bg="#000" style={{ scrollbarWidth: 'none' }}>
              <TerminalContainer />
            </DrawerBody>
          </Resizable>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Terminal;
