import React from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
} from '@chakra-ui/react';

import { stateSelector } from '@selectors';
import { setShowChat } from '@slices/terminalSlices';
import useDispatch from '@hooks/useDispatch';

import ChatbotContainer from './ChatbotContainer';
import { useTranslation } from 'next-i18next';

const Chatbot = () => {
  const dispatch = useDispatch();
  const state = useSelector(stateSelector);
  const { t } = useTranslation('common');

  const onClose = () => {
    dispatch(setShowChat(false));
  };

  return (
    <Drawer
      isOpen={state.terminal.showChat}
      placement="right"
      onClose={onClose}
      size="md"
      trapFocus={false}
    >
      <DrawerOverlay pointerEvents="none" bg="transparent">
        <DrawerContent pointerEvents="auto">
          <DrawerCloseButton />
          <DrawerHeader>{t('toolbar.chat')}</DrawerHeader>
          <DrawerBody p="0px">
            <ChatbotContainer />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default Chatbot;
