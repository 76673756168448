import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Head from 'next/head';

import {
  selectedProjectSelector,
  selectedProjectTemplateSlugSelector,
} from '@selectors';
import heartBeatService from '@services/HeartBeatService';
import { useOnEditorInit } from '@hooks/useOnAppInit';
import { ShowcaseInfoMain } from '@components/Main/Answers';
import { IDE } from '@components/Main/IDE';
import { ProjectType } from '@projectTypes/Project';
import { CustomPage } from '@/types';
import useOnEditorExit from '@hooks/useOnEditorExit';
import TopBanner from '@components/TopBanner';
import ModalContainer from '@components/ModalContainer';

import { useParam } from '@hooks/useParam';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetStaticProps } from 'next';
import LocaleSelect from '../components/LocaleSelect';

const Home: CustomPage = () => {
  useOnEditorInit();
  useOnEditorExit();

  const selectedProject = useSelector(selectedProjectSelector);
  const embedShowcase = useParam('embedShowcase') === 'true';
  const isGuest = useParam('isGuest') === 'true';
  const templateSlug = useSelector(selectedProjectTemplateSlugSelector);

  const [interval, appointInterval] = useState<any>();
  const { t } = useTranslation('common');

  useEffect(() => {
    if (interval !== undefined && interval !== null) {
      clearInterval(interval);
      appointInterval(null);
    }
    appointInterval(
      setInterval(() => {
        if (document.hasFocus()) {
          heartBeatService.heartBeat(
            `/editor/${(window as any).templateSlug}/`,
          );
        }
      }, 60000),
    );
  }, []);

  useEffect(() => {
    (window as any).templateSlug = templateSlug;
  }, [templateSlug]);

  return (
    <>
      <Head>
        <title>{t('STEMI Online IDE')}</title>
      </Head>

      <ModalContainer isGuest={isGuest} />
      <TopBanner />
      {embedShowcase || selectedProject?.type === ProjectType.ANSWERS ? (
        <ShowcaseInfoMain project={selectedProject} />
      ) : (
        <IDE />
      )}
    </>
  );
};

export const getStaticProps: GetStaticProps = async (p) => {
  const { locale } = p;

  const translations = await serverSideTranslations(locale, ['common']);

  return {
    props: {
      ...translations,
    },
    revalidate: 1,
  };
};

Home.persist = true;

export default Home;
