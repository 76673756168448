import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  Flex,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBoolean,
  UseDisclosureReturn,
  VStack,
} from '@chakra-ui/react';
import {
  Pagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from '@ajna/pagination';

import { terminalSelector } from '@selectors';
import { DataTable } from 'chakra-data-table';
import { CloseIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { PythonSavedData, TestData } from '@projectTypes/Python';
import { useTranslation } from 'next-i18next';

interface TestResultsModalProps {
  modalProps: UseDisclosureReturn;
  tests?: PythonSavedData[];
}

export const TestResultsModal: React.FC<TestResultsModalProps> = (props) => {
  const { modalProps, tests } = props;
  const { testResults } = useSelector(terminalSelector);
  const [testCounter, setTestCounter] = useState(-1);

  const [index, setIndex] = useState<number>(0);

  const oldTests: TestData[][] = tests
    ? tests
        .map((t) => {
          try {
            return JSON.parse(t.logs);
          } catch (e) {
            return [];
          }
        })
        .map((t) => {
          return t.map((suite) => ({
            name: suite.name || 'test',
            results: suite?.data?.data || [],
          }));
        })
    : [];

  useEffect(() => {
    if (oldTests.length && testCounter === -1) {
      setTestCounter(oldTests.length);
    } else if (oldTests.length === 0) {
      setTestCounter(-1);
    }
  }, [oldTests]);

  const [showAll, { toggle, off }] = useBoolean();

  useEffect(() => {
    if (!showAll && index > filteredResults.length) {
      setIndex(0);
    }
  }, [showAll]);

  useEffect(() => {
    off();
  }, [modalProps.isOpen]);

  const currentTest = oldTests.length ? oldTests[testCounter - 1] : testResults;
  const { t: tr } = useTranslation('common');
  const filteredResults = currentTest
    ? showAll
      ? currentTest
      : currentTest.filter((suite) => suite.results.some((r) => !r.test_pass))
    : [];

  return (
    <Modal size="6xl" {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex w="full" justify="space-between">
            <Text>Test results</Text>
            {oldTests.length > 1 && (
              <VStack>
                <Pagination
                  pagesCount={oldTests.length}
                  currentPage={testCounter}
                  onPageChange={(c) => {
                    const newCounter =
                      c < 1 ? 1 : c > oldTests.length ? oldTests.length : c;
                    setTestCounter(newCounter);
                  }}
                >
                  <PaginationContainer>
                    <PaginationPrevious>Previous</PaginationPrevious>
                    <PaginationPageGroup
                      style={{ marginLeft: 10, marginRight: 10 }}
                    >
                      {oldTests.map((suite, i) => (
                        <PaginationPage
                          key={i.toString()}
                          page={i + 1}
                          {...(suite.filter((suite) =>
                            suite.results.some((r) => !r.test_pass),
                          ).length === 0
                            ? {
                                background: 'green',
                                color: '#FFF',
                              }
                            : {})}
                          _hover={{
                            background: 'secondary',
                            color: '#FFF',
                          }}
                          _current={{
                            w: 8,
                            color: '#FFF',
                            background: 'primary',
                          }}
                        />
                      ))}
                    </PaginationPageGroup>
                    <PaginationNext>Next</PaginationNext>
                  </PaginationContainer>
                </Pagination>
                <Text>
                  {tests[testCounter]?.createdAt &&
                    new Date(tests[testCounter].createdAt).toLocaleString()}
                </Text>
              </VStack>
            )}

            <HStack alignItems="center">
              <Button
                size="sm"
                onClick={toggle}
                leftIcon={showAll ? <ViewOffIcon /> : <ViewIcon />}
              >
                {showAll ? 'Hide' : 'Show'} all results
              </Button>

              <IconButton
                size="sm"
                onClick={modalProps.onClose}
                icon={<CloseIcon />}
                aria-label={tr('ariaLabel.closeTestResultModal')}
              />
            </HStack>
          </Flex>
        </ModalHeader>

        <ModalBody>
          {filteredResults.length === 0 ? (
            <VStack>
              <Heading>All tests passed</Heading>
              <Text>
                Click on &quot;Show all results&quot; button to show all tests
              </Text>
            </VStack>
          ) : (
            <Tabs index={index} onChange={setIndex}>
              <TabList>
                {filteredResults.map((t) => (
                  <Tab key={t.name}>{t.name}</Tab>
                ))}
              </TabList>

              <TabPanels>
                {filteredResults.map((t) => (
                  <TabPanel key={t.name}>
                    <Text>{t.description}</Text>
                    <VStack>
                      t.failed && (
                        <Heading size="md">
                         { tr('tests.failedToRun')}
                        </Heading>
                      )

                      <DataTable
                        data={t.results.filter((r) =>
                          showAll ? true : !r.test_pass,
                        )}
                        keys={['id', 'comment', 'type', 'test_pass'] as const}
                        mapper={{
                          id: (r, i) => i.toString(),
                          comment: (r) => r.comment,
                          type: (r) => r.type,
                          test_pass: (r) => (r.test_pass ? '✅' : '❌'),
                        }}
                      />
                    </VStack>
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          )}
        </ModalBody>

        <ModalFooter>
          <Button onClick={modalProps.onClose}>{tr('file.modal.save')}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
