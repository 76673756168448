import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, IconButton, VStack } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

import { increaseFileCounter } from '@slices/fileSlices';
import ProjectContainer from '@components/ProjectContainer';
import config from '@config';
import { leftSidebarSelector, editorSelector } from '@selectors';
import { setIsLeftSidebarOpen } from '@slices/globalSlices';
import useDispatch from '@hooks/useDispatch';

import UserContainer from './UserContainer';
import { useTranslation } from 'next-i18next';

const LeftSidebar = () => {
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector(leftSidebarSelector);
  const editor = useSelector(editorSelector);
  const [allowed, setAllowed] = useState(false);
  const { t } = useTranslation('common');
  const toggleSidebar = () => {
    setTimeout(() => {
      dispatch(increaseFileCounter());
    }, 210);
    dispatch(setIsLeftSidebarOpen(!isSidebarOpen));
  };

  return (
    <Box
      borderRightColor={'#37373D'}
      borderRightWidth={'2px'}
      position="relative"
      pl="8px"
      display={editor.isSmall ? 'none' : 'block'}
      backgroundColor={'#181818'}
      transition="0.2s"
      width={isSidebarOpen ? '290px' : '0'}
      maxW={isSidebarOpen ? '300px' : '0'}
      onMouseLeave={allowed ? toggleSidebar : null}
    >
      <Box
        position="absolute"
        h="100vh"
        width="8px"
        left="0px"
        top={0}
        onMouseEnter={allowed ? toggleSidebar : null}
      />
      <VStack
        position="relative"
        h="calc(100% - 46px)"
        justifyContent="space-between"
        alignItems="flex-start"
        overflow="hidden"
        paddingTop="4px"
        paddingBottom="8px"
      >
        <ProjectContainer />
      </VStack>
      <IconButton
        icon={<HamburgerIcon color={'white'} />}
        onClick={() => {
          setAllowed(!allowed);
          toggleSidebar();
        }}
        onMouseEnter={(e) => e.stopPropagation()}
        top="4px"
        right="-40px"
        position="absolute"
        size="sm"
        aria-label={t('ariaLabel.openSidebar')}
        backgroundColor="#3055f6"
        zIndex={1}
      />
      {isSidebarOpen && <UserContainer />}
    </Box>
  );
};

export default LeftSidebar;
